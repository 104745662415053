import '../lib/sentry';
import '@hotwired/turbo-rails';
import 'bootstrap';
import 'flatpickr';
import 'flatpickr/dist/flatpickr.css';

import '@/assets/javascripts/lib/bootstrap-editable/bootstrap-editable';
import '@/assets/javascripts/lib/jquery-multiselect/jquery.multiselect';

/* jquery-ui core */
import 'jquery-ui/ui/version';

import 'jquery-ui/ui/data';
import 'jquery-ui/ui/disable-selection';
import 'jquery-ui/ui/focusable';
import 'jquery-ui/ui/form';
import 'jquery-ui/ui/ie';
import 'jquery-ui/ui/keycode';
import 'jquery-ui/ui/labels';
import 'jquery-ui/ui/jquery-patch.js';
import 'jquery-ui/ui/plugin';
import 'jquery-ui/ui/safe-active-element';
import 'jquery-ui/ui/safe-blur';
import 'jquery-ui/ui/scroll-parent';
import 'jquery-ui/ui/tabbable';
import 'jquery-ui/ui/unique-id';
import 'jquery-ui/ui/position';
import 'jquery-ui/ui/widget';

import 'jquery-ui/dist/themes/base/jquery-ui.css';
/* --- */

/* jquery-ui widgets */
// autocomplete
import 'jquery-ui/ui/widgets/menu';
import 'jquery-ui/ui/widgets/autocomplete';

// multiselect
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/sortable';
import '@/assets/javascripts/lib/multiselect/multiselect';
/* --- */

// dialog
import 'jquery-ui/ui/widgets/button';
import 'jquery-ui/ui/widgets/dialog';

// progressbar
import 'jquery-ui/ui/widgets/progressbar';
/* --- */

/* application styles */
import '@/styles/application/index.scss';

// import stimulus controllers
import "@/assets/javascripts/controllers/index";


// add custom turbo action to break out of a turbo-frame with a redirect
import {Turbo} from "@hotwired/turbo-rails";
Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target)
}
