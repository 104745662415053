import {Controller} from "@hotwired/stimulus";

// TODO: Get rid of jQuery
export default class extends Controller {
  connect() {
    if ($(this.element).hasClass("select2-hidden-accessible")) {
      // Select2 has been initialized, do nothing
    }
    else {
      $(this.element).select2();
    }
  }

  disconnect() {
    if ($(this.element).hasClass("select2-hidden-accessible")) {
      $(this.element).select2('destroy');
    }
  }
}
